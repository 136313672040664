<template>
    <div class="ClientStories page" id="ClientStoriesPage">
        <ClientStoriesProject />
    </div>
</template>

<script>
    export default {
        name: 'ClientStoriesView',
        components: {
            ClientStoriesProject: () => import('@/components/ClientStoriesProject')
        }
    };
</script>
